<template>
  <div style="display: none">
    <slot v-if="ready" />
  </div>
</template>

<script>
import { MarkerClusterGroup } from "leaflet.markercluster";
import { findRealParent, propsBinder } from "vue2-leaflet";
import { DomEvent } from "leaflet";

const props = {
  options: {
    type: Object,
    default() {
      return {};
    },
  },
};

export default {
  props,
  data() {
    return {
      ready: false,
    };
  },
  mounted() {
    this.mapObject = new MarkerClusterGroup(this.options);
    DomEvent.on(this.mapObject, this.$listeners);
    propsBinder(this, this.mapObject, props);
    this.ready = true;
    this.parentContainer = findRealParent(this.$parent);
    this.parentContainer.addLayer(this);
    this.$nextTick(() => {
      this.$emit("ready", this.mapObject);
    });
  },
  beforeDestroy() {
    this.parentContainer.removeLayer(this);
  },
  methods: {
    addLayer(layer, alreadyAdded) {
      if (!alreadyAdded) {
        this.mapObject.addLayer(layer.mapObject);
      }
    },
    removeLayer(layer, alreadyRemoved) {
      if (!alreadyRemoved) {
        this.mapObject.removeLayer(layer.mapObject);
      }
    },
  },
};
</script>
<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>
<style lang="sass">
.marker-cluster-small, .marker-cluster-medium
  background-color: rgb(151 153 149 / 60%) !important
  div
    background-color: rgb(104 110 101 / 60%) !important
    color: white !important
</style>
